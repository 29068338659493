@import "~react-image-gallery/styles/css/image-gallery.css";
@import "@fontsource/roboto/300.css";
@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/500.css";
@import "@fontsource/roboto/700.css";
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&family=Russo+One&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f5f5;
}

.navbar {
  background-color: #f6f5f5;
  color: #d3eca7;
}

.navbar .navbar-nav li a {
  color: #0c359e;
}

.navbar .navbar-nav li a.active {
  color: #0d1282;
}

h1 {
  color: #009c94;
}

h2 {
  color: #0c359e;
}

h3 {
  font-size: 15px;
  color: #0d1282;
}

p span {
  color: #334257;
}

p,
button {
  color: #000;
}

.leyenda {
  background-color: #fff;
  box-shadow:  20px 20px 60px #bebebe,
             -20px -20px 60px #ffffff;
  width: 100%;
  overflow: hidden;
  overflow-x: hidden;
  max-width: 100%;
}

.leyenda-title-right {
  color: #0067a3;
  font-size: 26px;
  animation: leynda-animation-right 10s infinite;
}

.leyenda-title-left {
  color: #0067a3;
  font-size: 26px;
  animation: leynda-animation-left 10s infinite;
}

@keyframes leynda-animation-right {
  0% {
    transform: translateX(-100%);
    /* transform: scale(80%); */
  }
  10% {
    transform: translateX(-75%);
  }
  20% {
    transform: translateX(-50%);
  }
  35% {
    transform: translateX(-25%);
  }
  50% {
    transform: translateX(0%);
  }
  60% {
    transform: translateX(25%);
    /* transform: scale(80%); */
  }
  70% {
    transform: translateX(50%);
    /* transform: scale(80%); */
  }
  85% {
    transform: translateX(75%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes leynda-animation-left {
  0% {
    transform: translateX(100%);
    /* transform: scale(80%); */
  }
  10% {
    transform: translateX(75%);
  }
  20% {
    transform: translateX(50%);
  }
  35% {
    transform: translateX(25%);
  }
  50% {
    transform: translateX(0%);
  }
  60% {
    transform: translateX(-25%);
    /* transform: scale(80%); */
  }
  70% {
    transform: translateX(-50%);
    /* transform: scale(80%); */
  }
  85% {
    transform: translateX(-75%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.title_full_view_scren {
  text-wrap: nowrap;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
}

.description_full_view_scren {
  text-align: justify;
  width: 75%;
  margin: 0 auto;
  color: #0f161f;
  font-size: 18px;
}

.title-service-page {
  color: rgba(0, 156, 148, 1);
  margin: 2rem auto;
  text-align: center;
  font-size: 38px;
}

.title-about-us {
  color: rgba(0, 156, 148, 1);
  margin: 2rem auto;
  text-align: center;
  font-size: 38px;
}

.title-gallery {
  color: rgba(0, 156, 148, 1);
  margin: 2rem auto;
  text-align: center;
  font-size: 38px;
}

.title-contact {
  color: rgba(0, 156, 148, 1);
  margin: 2rem auto;
  text-align: center;
  font-size: 38px;
}

.contact-us-page input, .contact-us-page textarea{
  background-color: #f6f5f5;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* genereted font */

.russo-one-regular {
  font-family: "Russo One", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.amaranth-regular {
  font-family: "Amaranth", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.amaranth-bold {
  font-family: "Amaranth", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.amaranth-regular-italic {
  font-family: "Amaranth", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.amaranth-bold-italic {
  font-family: "Amaranth", sans-serif;
  font-weight: 700;
  font-style: italic;
}

@media (max-width: 600px) {
  .form-contacto {
    width: 100%;
  }
}

@media (min-width: 601px) {
  .form-contacto {
    width: 50%;
  }
}

/* PERSONALIZADOS */
.img-servcios {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  background-position: center;
  border-radius: 10px;
}

/* Crear clase para difuminar el background */
.bg-blur {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}

/* boton de catalogo */
.btn-custoom-catalogo {
  background-color: #f44336;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 40vw;
  opacity: 0.9;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  transition: 0.3s;
}

.btn-custoom-catalogo:hover {
  background-color: #f44336;
  color: white;
  opacity: 1;
}

/* ----------------------------------------------
  Generated by AnimatiSS
  Licensed under FreeBSD License
  URL: https://xsgames.co/animatiss
  Twitter: @xsgames_
---------------------------------------------- */

.scale-up-top {
  animation: scale-up-top 0.4s;
}

@keyframes scale-up-top {
  0% {
    transform: scale(0.5);
    transform-origin: center top;
  }
  100% {
    transform: scale(1);
    transform-origin: center top;
  }
}

/* ----------------------------------------------
  Generated by AnimatiSS
  Licensed under FreeBSD License
  URL: https://xsgames.co/animatiss
  Twitter: @xsgames_
---------------------------------------------- */

.tracking-in-expand-forward-top {
  animation: tracking-in-expand-forward-top 0.9s linear both;
}
@keyframes tracking-in-expand-forward-top {
  0% {
    letter-spacing: -0.2em;
    transform: translateZ(-700px) translateY(-100px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
  Generated animation by css
---------------------------------------------- */

@keyframes show {
  from {
    opacity: 0;
    scale: 25%;
  }
  to {
    opacity: 1;
    scale: 100%;
  }
}

/* img {
  view-timeline-name: --image;
  view-timeline-axis: block;
  
  animation-timeline: --image;
  animation-name: show;

  animation-range: entry 15% cover 55%;
  animation-fill-mode: both; 
}

h1, h2, h3, h4, h5, h6 {
  view-timeline-name: --text;
  view-timeline-axis: block;

  animation-timeline: --text;
  animation-name: show;

  animation-range: entry 15% cover 25%;
  animation-fill-mode: both;

} */

/* ----------------------------------------------
  Generated timeline by css
 */

.timeline ul {
  background: #ffffff;
  padding: 50px 0;
}

.timeline ul li {
  list-style-type: none;
  position: relative;
  width: 6px;
  margin: 0 auto;
  padding-top: 50px;
  background: #bebebe;
}

.timeline ul li::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: inherit;
  z-index: 1;
}

.timeline ul li div {
  position: relative;
  bottom: 0;
  width: 400px;
  padding: 15px;
  border-radius: 5px;
  background: #e0e0e0;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
}

.timeline ul li div::before {
  content: "";
  position: absolute;
  bottom: 7px;
  width: 0;
  height: 0;
  border-style: solid;
}

.timeline ul li:nth-child(odd) div {
  left: 42px;
}

.timeline ul li:nth-child(odd) div::before {
  left: -15px;
  border-width: 8px 16px 8px 0;
  border-color: transparent #b9b9b9 transparent transparent;
}

.timeline ul li:nth-child(even) div {
  left: -434px;
}

.timeline ul li:nth-child(even) div::before {
  right: -15px;
  border-width: 8px 0 8px 16px;
  border-color: transparent transparent transparent #b9b9b9;
}

/* EFFECTS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline ul li::after {
  transition: background 0.5s ease-in-out;
}

.timeline ul li.in-view::after {
  border-radius: 50px;
  background: #b8b8b8;
  box-shadow: 7px 7px 13px #9c9c9c, -7px -7px 13px #d4d4d4;
}

.timeline ul li div {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.timeline ul li:nth-child(odd) div {
  transform: translate3d(200px, 0, 0);
}

.timeline ul li:nth-child(even) div {
  transform: translate3d(-200px, 0, 0);
}

.timeline ul li.in-view div {
  transform: none;
  visibility: visible;
  opacity: 1;
}

/* GENERAL MEDIA QUERIES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@media screen and (max-width: 900px) {
  .timeline ul li div {
    width: 250px;
  }
  .timeline ul li:nth-child(even) div {
    left: -289px;
    /*250+45-6*/
  }
}

@media screen and (max-width: 600px) {
  .timeline ul li {
    margin-left: 20px;
  }
  .timeline ul li div {
    width: calc(100vw - 91px);
  }
  .timeline ul li:nth-child(even) div {
    left: 45px;
  }
  .timeline ul li:nth-child(even) div::before {
    left: -15px;
    border-width: 8px 16px 8px 0;
    border-color: transparent #b9b9b9 transparent transparent;
  }
}

/* EXTRA/CLIP PATH STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.timeline-clippy ul li::after {
  width: 40px;
  height: 40px;
  border-radius: 0;
}

.timeline-rhombus ul li::after {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.timeline-rhombus ul li div::before {
  bottom: 12px;
}

.timeline-star ul li::after {
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}

.timeline-heptagon ul li::after {
  clip-path: polygon(
    50% 0%,
    90% 20%,
    100% 60%,
    75% 100%,
    25% 100%,
    0% 60%,
    10% 20%
  );
}

.timeline-infinite ul li::after {
  animation: scaleAnimation 2s infinite;
}

@keyframes scaleAnimation {
  0% {
    transform: translateX(-50%) scale(1);
  }
  50% {
    transform: translateX(-50%) scale(1.25);
  }
  100% {
    transform: translateX(-50%) scale(1);
  }
}

/* Location, Phone, Email Section */
.contact-list {
  list-style-type: none;
  margin-left: -30px;
  padding-right: 20px;
}

.list-item {
  line-height: 4;
  color: #aaa;
}

.contact-text {
  font: 300 18px "Lato", sans-serif;
  letter-spacing: 1.9px;
  color: #bbb;
}

.place {
  margin-left: 62px;
}

.phone {
  margin-left: 56px;
}

.gmail {
  margin-left: 53px;
}

.contact-text a {
  color: #bbb;
  text-decoration: none;
  transition-duration: 0.2s;
}

.contact-text a:hover {
  color: #fff;
  text-decoration: none;
}

/* Social Media Icons */
.social-media-list {
  position: relative;
  font-size: 22px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.social-media-list li a {
  color: #fff;
}

.social-media-list li {
  position: relative;
  display: inline-block;
  height: 60px;
  width: 60px;
  margin: 10px 3px;
  line-height: 55px;
  border-radius: 50%;
  color: #fff;
  background-color: rgb(27, 27, 27);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.social-media-list li:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  line-height: 50px;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0 0 0 1px #fff;
  transition: all 0.2s ease-in-out;
}

.social-media-list li:hover {
  background-color: #fff;
}

.social-media-list li:hover:after {
  opacity: 1;
  transform: scale(1.12);
  transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
}

.social-media-list li:hover a {
  color: #000;
}

.copyright {
  font: 200 14px "Oswald", sans-serif;
  color: #555;
  letter-spacing: 1px;
  text-align: center;
}

hr {
  border-color: rgba(255, 255, 255, 0.6);
}
